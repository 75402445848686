import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { StarIcon } from "@heroicons/react/solid"
import SchemaLocalBusinessWithReviews from "./layouts/schemas/SchemaLocalBusinessWithReviews"
import Container from "./containers/Container"

const TestmonialSlider = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      allReviews {
        nodes {
          author
          dateLocale: date(formatString: "DD.MM.YYYY")
          date
          id
          rating
          text
        }
        totalCount
      }
    }
  `)

  return (
    <>
      <SchemaLocalBusinessWithReviews />
      <Container>
        <div className="max-w-2xl mx-auto">
          <CarouselProvider
            visibleSlides={1}
            totalSlides={data.allReviews.totalCount}
            step={1}
            naturalSlideWidth={320}
            naturalSlideHeight={100}
            infinite={true}
            isIntrinsicHeight={true}
            className={`${className ? className : ""}`}
          >
            <Slider>
              {data.allReviews.nodes.map((review, i) => {
                let stars = []
                for (let i = 1; i <= review.rating; i++) {
                  stars.push(
                    <StarIcon
                      className="inline-block w-4 h-4 text-yellow-300"
                      key={i}
                    />
                  )
                }
                const textOutput = review.text !== null ? review.text : ""

                return (
                  <Slide index={i} key={i}>
                    <div className="relative cursor-grab">
                      <figure>
                        <div className="mb-3">{stars}</div>
                        <blockquote className="mb-3">
                          {textOutput.split("").length > 300
                            ? textOutput.slice(0, 300) + "..."
                            : textOutput}
                        </blockquote>
                        <figcaption className="text-right">
                          {review.dateLocale}
                          {" • "}
                          <cite className="font-bold">
                            {review.author ? review.author : "Anonym"}{" "}
                          </cite>
                        </figcaption>
                      </figure>
                    </div>
                  </Slide>
                )
              })}
            </Slider>
            <div className="flex justify-end my-3 space-x-3">
              <ButtonBack className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap transition duration-150 ease-in-out bg-blue-500 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
                Zurück
              </ButtonBack>
              <ButtonNext className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap transition duration-150 ease-in-out bg-blue-500 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
                Nächste
              </ButtonNext>
            </div>
            <div>
              <p className="text-right text-gray-700">
                <a
                  href="https://www.google.com/search?q=florian+dierda&oq=florian+dierda#lrd=0x47bb3fb34d2c4765:0xea2eda6b708ff861,1,,,"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Zu allen Bewertungen
                </a>
              </p>
            </div>
          </CarouselProvider>
        </div>
      </Container>
    </>
  )
}

export default TestmonialSlider
