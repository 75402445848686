import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SchemaLocalBusiness = () => {
  const data = useStaticQuery(
    graphql`
      {

        site {
          siteMetadata {
            title
            siteUrl
            contact{
              mail
            }
          }
        }


        allGooglePlaces {
          nodes {
            result {
              address_components {
                long_name
                short_name
                types
              }
              international_phone_number
              opening_hours {
                periods {
                  open {
                    time
                  }
                  close {
                    time
                  }
                }
              }
              geometry {
                location {
                  lat
                  lng
                }
              }
            }
          }
        }

        logo: file(relativePath: { eq: "structured-data/logo.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        profile: file(
          relativePath: { eq: "structured-data/profil.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }

        allReviews {
          nodes {
            author
            dateLocale: date(formatString: "DD.MM.YYYY")
            date
            id
            rating
            text
          }
          totalCount
        }


      }
    `
  )


  let aggregateRating = 0
  data.allReviews.nodes.forEach(review => {
    aggregateRating = aggregateRating + review.rating
  })
  aggregateRating = aggregateRating / data.allReviews.totalCount.toFixed(2)

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "@id": data.site.siteMetadata.siteUrl,
    name: data.site.siteMetadata.title,
    geo: {
      "@type": "GeoCoordinates",
      latitude: data.allGooglePlaces.nodes[0].result.geometry.location.lat,
      longitude: data.allGooglePlaces.nodes[0].result.geometry.location.lng,
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: `${data.allGooglePlaces.nodes[0].result.address_components[1].long_name} ${data.allGooglePlaces.nodes[0].result.address_components[0].long_name}`,
      postalCode: data.allGooglePlaces.nodes[0].result.address_components[8].long_name,
      addressLocality: data.allGooglePlaces.nodes[0].result.address_components[3].long_name,
      addressCountry: data.allGooglePlaces.nodes[0].result.address_components[7].short_name,
    },
    image: `${data.site.siteMetadata.siteUrl}${data.profile.childImageSharp.gatsbyImageData.images.fallback.src}`,
    logo: `${data.site.siteMetadata.siteUrl}${data.logo.childImageSharp.gatsbyImageData.images.fallback.src}`,
    telephone: data.allGooglePlaces.nodes[0].result.international_phone_number,
    url: data.site.siteMetadata.siteUrl,
    email: data.site.siteMetadata.contact.mail,
    sameAs: [
      "https://www.facebook.com/dierda.finanz/",
    ],
    openingHours: [
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[0].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[0].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[1].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[1].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[2].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[2].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[3].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[3].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[4].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[4].close.time.slice(
            0,
            2
          ) + ":00",
      },
    ],
    aggregateRating: {
      "@type": "aggregateRating",
      worstRating: 1,
      bestRating: 5,
      reviewCount: data.allReviews.totalCount.toString(),
      ratingValue: aggregateRating.toString(),
    },
    review: [],
  }

  data.allReviews.nodes.forEach(review => {
    const schemaData = {
      "@type": "Review",
      author: review.author ? review.author : "Anonym",
      reviewRating: {
        "@type": "Rating",
        worstRating: 1,
        bestRating: 5,
        ratingValue: review.rating.toString(),
      },
      itemReviewed: "Florian Dierda Finanzdienstleistungen",
      reviewBody: review.text,
    }
    ldJson.review.push(schemaData)
  })
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}

export default SchemaLocalBusiness
